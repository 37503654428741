@import url("https://fonts.googleapis.com/css2?family=Amaranth:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --hidden: false;
}
.hide {
  display: var(--hidden);
}

.btn-xs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-5 py-2 px-3 border-slate-200 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-textarea {
  @apply placeholder-gray-400;
}

.form-select {
  @apply pr-10;
}

.form-textarea,
.form-multiselect,
.form-select,
.form-radio {
  @apply text-sm text-gray-800 bg-white border;
}

/* styles.css */
.tab-transition {
  transition: border-bottom 0.3s ease-in-out;
}

.tab-panel-transition {
  transition: opacity 0.3s ease-in-out;
}

.btn,
.btn-lg,
.btn-sm,
.btn-xs,
.btn-chart {
  @apply font-medium text-sm  dark:text-zinc-300 dark:border-zinc-700 border rounded leading-5 shadow-sm transition duration-150 ease-in-out text-center;
}

.btn {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-4 py-3;
}

.btn-sm {
  @apply px-2 py-1;
}
.btn-chart {
  @apply px-2 py-1 w-[100px] dark:bg-[#1a1a1a] dark:border-zinc-800 text-sm;
}

.btn-xs {
  @apply px-2 py-0.5;
}
.form-checkbox {
  @apply w-4 h-4 text-brand_50 rounded focus:ring-brand_50 dark:bg-zinc-800 bg-zinc-200;
}

.form-input {
  @apply border-slate-200 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-textarea {
  @apply border-slate-200 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-search {
  @apply border-slate-200 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.hover-transition {
  @apply shadow-lg z-20 hover:shadow-xl transition-all duration-300 ease-in-out hover:scale-105;
}
.circular-progress text {
  @apply dark:text-white;
}

.c {
  background-color: rgb(247 248 252);
}

body:not(.dark) .dark-unstyled-content {
  display: none;
}

/* Dark mode: Show dark-styled content */
body.dark .dark-unstyled-content {
  display: block;
}

.dark .react-loading-skeleton::after {
  background-image: linear-gradient(90deg, #1a1a1a, #171717, #1a1a1a);
}

.stats-gradient {
  background: linear-gradient(
    75.96deg,
    #f66a3e 13.64%,
    #f73753 46.53%,
    #d32471 78.88%
  );
}

.nav-shadow {
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px;
}
.dark .nav-shadow {
  box-shadow: none;
}

.mySwiper {
  padding: 0 1rem 4rem !important;
}

@media (min-width: 850px) {
  .mySwiper {
    padding-bottom: 6rem !important;
  }
}

.mySwiperInstructor {
  padding: 0 1rem 4rem !important;
}

@media (min-width: 850px) {
  .mySwiperInstructor {
    padding-bottom: 6rem !important;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(270deg, transparent 2%, #ee4b2b);
}

.youtube-modal {
  padding: 2em;
  display: flex;
  justify-content: center;
  text-align: center;
  /* box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29; */
  align-items: center;
}

iframe {
  max-width: 100%;
}

.form-switch {
  @apply relative select-none;
  width: 44px;
}
.form-switch label {
  @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}
.form-switch label > span:first-child {
  @apply absolute block rounded-full;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  right: 50%;
  transition: all 0.15s ease-out;
}
.form-switch input[type="checkbox"]:checked + label {
  @apply bg-brand;
}
.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 22px;
}
.form-switch input[type="checkbox"]:disabled + label {
  @apply cursor-not-allowed bg-gray-100 border border-gray-200;
}
.form-switch input[type="checkbox"]:disabled + label > span:first-child {
  @apply bg-gray-400;
}

/* .theme-toggle {
  @apply p-2 rounded-lg border-brand_50 border-2
} */
.theme-toggle svg {
  @apply w-6 h-6 fill-brand stroke-brand;
}
.theme-toggle svg g g {
  @apply stroke-brand;
}

.btn-brand {
  @apply px-8 py-3 rounded-lg text-white bg-brand hover:bg-brand_50 w-full;
}
.btn-brand-2 {
  @apply rounded-lg relative inline-flex items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-brand active:shadow-none shadow-lg bg-gradient-to-tr from-brand to-brand_50 hover:from-brand_50 hover:to-brand_40  border-brand_60 text-white;
}

.dark .ql-editor {
  color: #f5f5f5;
}

.dark .ql-editor placeholder {
  color: #f5f5f5;
}

.dark .ql-toolbar.ql-snow {
  border: 2px solid #3f3f46 !important;
  background-color: #3f3f4600 !important;
}

.ql-snow.ql-toolbar button {
  height: 28px !important;
  width: 28px !important;
}
.dark .ql-container.ql-snow {
  border: 2px solid #3f3f46 !important;
}

.ql-container.ql-snow {
  border: 2px solid #e4e4e7 !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}
.ql-toolbar.ql-snow {
  border: 2px solid #e4e4e7 !important;
  background-color: #f5f5f5;
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px !important;
}

.ql-toolbar.ql-snow {
  font-family: "Public Sans";
}

.dark .ql-editor.ql-blank::before {
  color: #494949 !important;
}

.discussion-height {
  height: 150px !important;
}

.notes-height {
  height: 200px !important;
}

.vimeo-div[data-v-a8a349ba] {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  aspect-ratio: 16/9;
}

.overflow-hidden::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}
.overflow-hidden {
  scrollbar-width: none !important;
}

.premium-split {
  height: calc(100vh - 128px);
}

.action-sash-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.1);
}

.swiper-wrapper {
  transition-timing-function: linear;
}

.rich-editor-height {
  height: 150px !important;
}

.dark .css-13cymwt-control {
  background-color: #1a1a1a !important;
}
.dark .css-1nmdiq5-menu {
  background-color: #1a1a1a !important;
}

.dark .select__control--is-focused {
  background-color: #1a1a1a !important;
}

.dark .select__option.select__option--is-focused.css-d7l1ni-option {
  background-color: #111111;
  box-sizing: border-box;
  color: rgba(243, 243, 243, 0.918);
  cursor: default;
  font-family: Lato;
  line-height: 24px;
  padding: 8px 12px;
  user-select: none;
}

.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: 10em;
  height: 10em;
  background: #1f1f1f;
  -webkit-box-shadow: 3em 2.5em 0 0em #fff inset,
    rgba(255, 255, 255, 0.2) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.2) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.2) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -1em 10em 0 -4.5em;
  box-shadow: 3em 2.5em 0 0em #fff inset,
    rgba(255, 255, 255, 0.2) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.2) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.2) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -1em 10em 0 -4.5em;
}

.sun {
  top: 4.5em;
  left: 20em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #facc15;
  -webkit-box-shadow: 3em 3em 0 5em #facc15 inset, 0 -5em 0 -2.7em #facc15,
    3.5em -3.5em 0 -3em #facc15, 5em 0 0 -2.7em #facc15,
    3.5em 3.5em 0 -3em #facc15, 0 5em 0 -2.7em #facc15,
    -3.5em 3.5em 0 -3em #facc15, -5em 0 0 -2.7em #facc15,
    -3.5em -3.5em 0 -3em #facc15;
  box-shadow: 3em 3em 0 5em #facc15 inset, 0 -5em 0 -2.7em #facc15,
    3.5em -3.5em 0 -3em #facc15, 5em 0 0 -2.7em #facc15,
    3.5em 3.5em 0 -3em #facc15, 0 5em 0 -2.7em #facc15,
    -3.5em 3.5em 0 -3em #facc15, -5em 0 0 -2.7em #facc15,
    -3.5em -3.5em 0 -3em #facc15;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

* {
  scrollbar-color: #161617 transparent;
  scrollbar-width: thin;
}

/* Tags Modal Styles */

.tags-modal {
  position: fixed;
  background: linear-gradient(
    149.45deg,
    rgba(255, 255, 255, 0.1) 14.25%,
    rgba(255, 255, 255, 0.4) 85.42%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 999;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
}
.tags-modal.show {
  display: grid;
  place-items: center;
}
.tags-modal .tags {
  padding: 15px;
  width: 570px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}
.tags-modal .tags__close {
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.tags-modal .tags__header {
  font-size: 24px;
  font-weight: 500;
}
.tags-modal .tags__pills {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.tags-modal .tags__pills--item {
  background-color: #ec3713;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  .tags-modal .tags {
    width: 90vw;
  }
  .tags-modal .tags__header h2 {
    font-size: 18px;
  }
  .tags-modal .tags__pills--item {
    padding: 5px 10px;
    font-size: 14px;
    height: min-content;
  }
}

/* Notes Modal Styles */

.notes-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(
    149.45deg,
    rgba(255, 255, 255, 0.1) 14.25%,
    rgba(255, 255, 255, 0.4) 85.42%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 999;
  top: 0;
  left: 0;
  display: none;
}
.notes-modal.show {
  display: grid;
  place-items: center;
}
.notes-modal .notes {
  padding: 15px;
  width: min(670px, 95vw);
  height: 100%;
  background: #fff9f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.notes-modal .notes__header {
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fdebe7;
}
.notes-modal .notes__header--title {
  font-size: 18px;
  font-weight: 400;
  max-width: 465px;
  margin: 0;
}
.notes-modal .notes__header--buttons {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-left: auto;
}
.notes-modal .notes__header--buttons button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.3em 1em;
  color: white;
  border-radius: 5px;
  height: min-content;
}
.notes-modal .notes__header--buttons button.hide {
  display: none;
}
.notes-modal .notes__header--buttons button#edit-notes-btn {
  background-color: black;
}
.notes-modal .notes__header--buttons button#save-notes-btn {
  background-color: green;
}
.notes-modal .notes__edit {
  padding: 10px;
  margin-bottom: auto;
  height: 100%;
}
.notes-modal .notes__edit.hide {
  display: none;
}
.notes-modal .notes__edit textarea {
  width: calc(100% - 1em);
  height: calc(100% - 1em);
  color: #828282;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  padding: 0.5em;
}
.notes-modal .notes__edit textarea::placeholder {
  color: #828282;
}
.notes-modal .notes__view {
  padding: 10px;
  height: 600px;
  overflow-y: auto;
}
.notes-modal .notes__view.hide {
  display: none;
}
.notes-modal .notes__footer p {
  color: #bdbdbd;
  font-weight: 400;
  font-size: 14px;
  text-align: end;
}
.notes-modal .notes__footer p.limit {
  color: red;
}

@media only screen and (max-width: 600px) {
  .notes-modal .notes {
    width: 90vw;
    padding: 15px 5px;
  }
  .notes-modal .notes__edit {
    padding: 10px 0;
  }
  .notes-modal .notes__edit textarea {
    width: 100%;
  }
}

/* YT Modal Styles */

.yt-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: none;
  background: linear-gradient(
    149.45deg,
    rgba(255, 255, 255, 0.1) 14.25%,
    rgba(255, 255, 255, 0.4) 85.42%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.yt-modal.show {
  display: grid;
  place-items: center;
}
.yt-modal .yt-sol {
  padding: 15px;
  position: relative;
  background: #fff9f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.yt-modal .yt-sol__close {
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.yt-modal .yt-sol__header {
  font-size: 24px;
  font-weight: 500;
}
.yt-modal .yt-sol__embed {
  width: min(930px, 63vw);
  aspect-ratio: 930 / 523 !important;
}
.yt-modal .yt-sol__embed iframe {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.site {
  max-width: 1500px;
}

.site-content {
  padding: 0em 0 1em;
}

.more-link {
  background-color: #ee4b2b;
}

.pagination .current {
  background-color: #ee4b2b;
}

.es_subscription_form input[type="submit"] {
  background-color: #ee4b2b;
}

.wp-block-group a {
  color: #ee4b2b;
}

.wa_btn a {
  color: #ffffff;
}

.wa_share {
  text-decoration: none;
  background-color: #ee4b2b;
  border: none;
  color: #ffffff;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
}

#diqus_loader {
  margin-left: 40%;
}

@media (max-width: 767px) {
  #diqus_loader {
    margin-left: 19%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #diqus_loader {
    margin-left: 35%;
  }
}
#toc_container {
  background: #f9f9f9 none repeat scroll 0 0;
  border: 1px solid #aaa;
  display: table;
  font-size: 95%;
  margin-bottom: 1em;
  padding: 20px;
  width: auto;
}

.toc_title {
  font-weight: 700;
  text-align: center;
}

#toc_container li,
#toc_container ul,
#toc_container ul li {
  list-style: outside none none !important;
}

/* Tracker CSS */
.outer-box-tracker {
  z-index: 1;
  position: relative;
  width: 200px;
  display: block;
  margin-right: 1em;
}
.outer-box-tracker .text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1rem;
  font-weight: 600;
  font-size: 10px;
}
.outer-box-tracker .box-of-tracker {
  height: 18px;
  background-color: #fbd7d0;
  width: 100%;
}
.outer-box-tracker .box-of-tracker .inner-filling {
  height: 100%;
  background-color: rgba(236, 55, 19, 1) !important;
}
.outer-box-tracker.global {
  padding: 10px 20px;
  width: 100%;
  margin: 15px 0 30px 0;
  background: linear-gradient(
    94.8deg,
    rgba(255, 255, 255, 0.1) 46.13%,
    rgba(255, 255, 255, 0.4) 59.11%
  );
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  z-index: 3;
  position: sticky;
  top: 58px;
  display: flex;
  align-items: center;
  gap: 1em;
}
.outer-box-tracker.global .text {
  font-size: 20px;
  top: 17px;
  transform: translateX(-50%);
  font-weight: 600;
}
.outer-box-tracker.global .box-of-tracker {
  height: 25px;
}
.outer-box-tracker.global p {
  margin-top: 0;
  min-width: max-content;
}

@media only screen and (max-width: 600px) {
  .outer-box-tracker {
    grid-column: 1 / 3;
  }
  .outer-box-tracker.global {
    display: block;
  }
  .outer-box-tracker.global .text {
    top: 13px;
  }
}

/* Loader CSS */

.topics-container .loader {
  text-align: center;
}

/* SDE CSS */

details.top-level {
  margin-bottom: 20px;
}
details.top-level[open] > summary::after {
  transform: rotate(45deg);
  color: #000;
}
details.top-level:hover > summary {
  background: linear-gradient(
    270deg,
    rgba(236, 55, 19, 0.039) 0%,
    rgba(236, 55, 19, 0.156) 100%
  );
  border: 1px solid rgba(236, 55, 19, 0.2);
}
details.top-level > summary {
  padding: 08px 20px 8px 20px;
  background: linear-gradient(
    90deg,
    rgba(240, 95, 66, 0.068) 0%,
    rgba(242, 115, 90, 0.017) 100%
  );
  box-shadow: 0px 1px 5px 0.5px rgba(236, 55, 19, 0.12);
  backdrop-filter: blur(20px);
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  column-gap: 4px;
  transition: background 300ms;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6rem;
  border: 1px solid rgba(236, 55, 19, 0);
  margin-bottom: 8px;
}
details.top-level > summary::after {
  content: "+";
  color: #000000;
  position: absolute;
  font-size: 1.75rem;
  right: 0.25em;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details.top-level > summary.active {
  background: linear-gradient(
    270deg,
    rgba(236, 55, 19, 0.039) 0%,
    rgba(236, 55, 19, 0.156) 100%
  );
  border: 1px solid rgba(236, 55, 19, 0.2);
}
details.top-level > summary > b {
  color: #303030;
  font-size: 20px;
  font-weight: 600;
}
details.top-level > table {
  width: 95%;
  margin: 0 auto;
  background-color: #fff1ee;
  padding: 5px;
  color: #303030;
  border-spacing: 5px;
  border-collapse: separate;
}
details.top-level > table thead th {
  font-size: 18px;
  font-weight: 400;
  background-color: #ffffff;
  padding: 11px;
  line-height: 1rem;
  text-align: center;
  border: none;
  min-width: max-content;
}

details.top-level > table tbody tr.complete td {
  border: 1px solid rgba(32, 223, 77, 0.25);
  box-shadow: 0px 0px 10px rgba(54, 226, 94, 0.15);
  background-color: rgba(32, 223, 77, 0.25);
}
details.top-level > table tbody tr.revision td {
  border: 1px solid rgba(255, 193, 7, 0.5);
  box-shadow: 0px 0px 10px rgba(255, 193, 7, 0.15);
  background-color: rgba(255, 193, 7, 0.2);
}
details.top-level > table tbody tr td {
  padding: 5px 10px;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 1rem;
  word-break: break-all;
  border: none;
}
details.top-level > table tbody tr td:first-child {
  width: 20px;
  background: #fff1ee;
  border: none;
  box-shadow: none;
  padding: 0;
}
details.top-level > table tbody tr td:nth-child(2) {
  text-align: start;
  width: 40ch;
}
details.top-level > table tbody tr td select {
  font-size: 15px;
  border: 1px solid rgba(255, 193, 7, 0);
  outline: none;
  padding: 6px 3px;
  height: 100%;
}
details.top-level > table tbody tr td select.complete {
  background-color: rgba(32, 223, 77, 0.25);
  border: 1px solid rgb(32, 223, 77);
}
details.top-level > table tbody tr td select.revision {
  background-color: rgba(255, 193, 7, 0.25);
  border: 1px solid rgb(255, 193, 7);
}
details.top-level > table tbody tr td a {
  color: rgb(238, 75, 43);
  cursor: pointer !important;
}
details.top-level > table tbody tr td button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
details.top-level > table tbody tr td button img {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 600px) {
  details.top-level {
    overflow-x: scroll;
  }
  details.top-level > summary {
    grid-template-columns: max-content 1fr;
    font-size: 16px;
  }
  details.top-level > summary > b {
    font-size: 16px;
  }
}

/* A2Z Styles */

details.top-level details {
  width: 95%;
  margin: 15px auto;
}
details.top-level details[open] > summary::after {
  transform: rotate(45deg);
  color: #000;
}
details.top-level details:hover > summary {
  background: linear-gradient(
    270deg,
    rgba(236, 55, 19, 0.039) 0%,
    rgba(236, 55, 19, 0.156) 100%
  );
  border: 1px solid rgba(236, 55, 19, 0.2);
  backdrop-filter: blur(20px);
}
details.top-level details summary {
  padding: 5px 20px 5px 20px;
  background: linear-gradient(
    90deg,
    rgba(240, 95, 66, 0.068) 0%,
    rgba(242, 115, 90, 0.017) 100%
  );
  box-shadow: 0px 1px 5px 0.5px rgba(236, 55, 19, 0.1);
  backdrop-filter: blur(20px);
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  column-gap: 4px;
  transition: background 300ms;
  cursor: pointer;
  font-size: 17px;
  line-height: 1.6rem;
  font-weight: 300;
  border: 1px solid rgba(236, 55, 19, 0);
  margin-bottom: 8px;
}
details.top-level details summary::after {
  content: "+";
  color: #000000;
  position: absolute;
  font-size: 1.75rem;
  right: 0.25em;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details.top-level details summary.active {
  background: linear-gradient(
    270deg,
    rgba(236, 55, 19, 0.039) 0%,
    rgba(236, 55, 19, 0.156) 100%
  );
  border: 1px solid rgba(236, 55, 19, 0.2);
  backdrop-filter: blur(20px);
}
details.top-level details table {
  width: 95%;
  margin: 0 auto;
  background-color: #fff1ee;
  padding: 5px;
  color: #303030;
  border-spacing: 5px;
  border-collapse: separate;
}
details.top-level details table thead th {
  font-size: 16px;
  font-weight: 400;
  background-color: #ffffff;
  padding: 8px;
  line-height: 1rem;
  text-align: center;
  border: none;
  min-width: max-content;
}
details.top-level details table tbody tr.complete td {
  border: 1px solid rgba(32, 223, 77, 0.25);
  box-shadow: 0px 0px 10px rgba(54, 226, 94, 0.15);
  background-color: rgba(32, 223, 77, 0.25);
}
details.top-level details table tbody tr.revision td {
  border: 1px solid rgba(255, 193, 7, 0.5);
  box-shadow: 0px 0px 10px rgba(255, 193, 7, 0.15);
  background-color: rgba(255, 193, 7, 0.2);
}
details.top-level details table tbody tr td {
  padding: 5px 10px;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 1rem;
  word-break: break-all;
  border: none;
}
details.top-level details table tbody tr td:first-child {
  width: 20px;
  background: #fff1ee;
  border: none;
  box-shadow: none;
  padding: 0;
}
details.top-level details table tbody tr td:nth-child(2) {
  text-align: start;
  width: 44ch !important;
}
details.top-level details table tbody tr td select {
  font-size: 15px;
  border: 1px solid rgba(255, 193, 7, 0);
  outline: none;
  padding: 6px 3px;
}
details.top-level details table tbody tr td select.complete {
  background-color: rgba(32, 223, 77, 0.25);
  border: 1px solid rgb(32, 223, 77);
}
details.top-level details table tbody tr td select.revision {
  background-color: rgba(255, 193, 7, 0.25);
  border: 1px solid rgb(255, 193, 7);
}
details.top-level details table tbody tr td a {
  color: rgb(238, 75, 43);
  cursor: pointer !important;
}
details.top-level details table tbody tr td button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
details.top-level details table tbody tr td button img {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 600px) {
  details.top-level details summary {
    grid-template-columns: max-content 1fr;
    font-size: 16px;
  }
  details.top-level details summary > b {
    font-size: 16px;
  }
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-3em));
  }
}

/* Details Summary CSS */
.containerCard {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.card {
  box-shadow: 1px 1px 10px 1px #a1a1a1;
  border-radius: 10px;
  padding: 20px;
}

.main-profile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-info img {
  width: 100px;
  border-radius: 50%;
}

.user-info h3 {
  font-size: 1.3rem;
}

.user-info p {
  color: #5b7083;
}

.follow-btn a {
  font-size: 1.5rem;
  border: 3px solid #1da1f2;
  border-radius: 25px;
  background-color: rgba(29, 161, 242, 0);
  padding: 8px 20px;
  margin: 5px;
  font-weight: bold;
  color: #1da1f2;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.follow-btn a:hover {
  background-color: rgba(29, 161, 242, 0.2);
}

.user-bio {
  color: #0f1419;
  margin-bottom: 10px;
}

.user-bio a {
  color: #1da1f2;
  text-decoration: none;
}

.user-bio a:hover {
  text-decoration: underline;
}

.user-follows {
  display: flex;
  color: #5b7083;
}

.user-follows div {
  margin-right: 10px;
}

.user-follows b {
  color: black;
}

.navAds {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 2;
}

.navAds a {
  text-decoration: none;
  color: red;
  font-weight: bold;
}

/* Sticky Ads */
.sticky-ads {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  left: 50%;
  min-height: 70px;
  max-height: 200px;
  padding: 0px 0;
  box-shadow: 0 -6px 18px 0 rgba(9, 32, 76, 0.1);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  z-index: 20;
}

@media (max-width: 767px) {
  .sticky-ads {
    left: 0%;
    width: 100%;
  }
}
.sticky-ads-close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 0 0;
  position: absolute;
  right: 0;
  top: -30px;
  background-color: #fefefe;
  box-shadow: 0 -6px 18px 0 rgba(9, 32, 76, 0.08);
}

.sticky-ads .sticky-ads-close svg {
  width: 22px;
  height: 22px;
  fill: #000;
}

.sticky-ads .sticky-ads-content {
  overflow: hidden;
  display: block;
  position: relative;
  height: 70px;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

/*timeline*/
/*
  !!!!
  This pen is being refactored
  !!!!
  */
/*
  =====
  DEPENDENCES
  =====
  */
.r-title {
  margin-top: var(--rTitleMarginTop, 0) !important;
  margin-bottom: var(--rTitleMarginBottom, 0) !important;
}

p:not([class]):first-child {
  margin-top: 0;
}

/*
  text component
  */
/*
  time component
  */
/*
  core styles
  */
.time {
  display: var(--timeDisplay, inline-flex);
}

/*
  extensions
  */
.time__month {
  margin-left: var(--timelineMounthMarginLeft, 0.25em);
}

/*
  skin
  */
.time {
  padding: var(--timePadding, 0.25rem 1.25rem 0.25rem);
  background-color: var(--timeBackgroundColor, #f0f0f0);
  font-size: var(--timeFontSize, 0.75rem);
  font-weight: var(--timeFontWeight, 700);
  text-transform: var(--timeTextTransform, uppercase);
  color: var(--timeColor, currentColor);
}

/*
  card component
  */
/*
  core styles
  */
.card {
  padding: var(--timelineCardPadding, 1.5rem 1.5rem 1.25rem);
}

.card__content {
  margin-top: var(--cardContentMarginTop, 0.5rem);
}

/*
  skin
  */
.card {
  border-radius: var(--timelineCardBorderRadius, 2px);
  border-left: var(--timelineCardBorderLeftWidth, 3px) solid
    var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
  box-shadow: var(
    --timelineCardBoxShadow,
    0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.24)
  );
  background-color: var(--timelineCardBackgroundColor, #fff);
}

/*
  extensions
  */
.card__title {
  --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
  font-size: var(--cardTitleFontSize, 1.25rem);
}

/*
  =====
  CORE STYLES
  =====
  */
.timeline {
  display: var(--timelineDisplay, grid);
  grid-row-gap: var(--timelineGroupsGap, 2rem);
}

/*
  1. If timeline__year isn't displaed the gap between it and timeline__cards isn't displayed too
  */
.timeline__year {
  margin-bottom: 1.25rem; /* 1 */
}

.timeline__cards {
  display: var(--timeloneCardsDisplay, grid);
  grid-row-gap: var(--timeloneCardsGap, 1.5rem);
}

/*
  =====
  SKIN
  =====
  */
.timeline {
  --uiTimelineMainColor: var(--timelineMainColor, #222);
  --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);
  border-left: var(--timelineLineWidth, 3px) solid
    var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.timeline__year {
  --timePadding: var(--timelineYearPadding, 0.5rem 1.5rem);
  --timeColor: var(--uiTimelineSecondaryColor);
  --timeBackgroundColor: var(--uiTimelineMainColor);
  --timeFontWeight: var(--timelineYearFontWeight, 400);
}

.timeline__card {
  position: relative;
  margin-left: var(--timelineCardLineGap, 1rem);
}

/*
  1. Stoping cut box shadow
  */
.timeline__cards {
  overflow: hidden;
  padding-top: 0.25rem; /* 1 */
  padding-bottom: 0.25rem; /* 1 */
}

.timeline__card::before {
  content: "";
  width: 100%;
  height: var(--timelineCardLineWidth, 2px);
  background-color: var(
    --timelineCardLineBackgroundColor,
    var(--uiTimelineMainColor)
  );
  position: absolute;
  top: var(--timelineCardLineTop, 1rem);
  left: -50%;
  z-index: -1;
}

/*
  =====
  SETTINGS
  =====
  */
/**/
.timeline {
  --timelineMainColor: #4557bb;
}

/*
  =====
  DEMO
  =====
  */
.page {
  max-width: 47rem;
  padding: 5rem 2rem 3rem;
  margin-left: auto;
  margin-right: auto;
}

.substack {
  border: 1px solid #eee;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  height: 280px;
  margin: 1rem auto;
}

.linktr {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgba(8, 49, 112, 0.24) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
}

#myProgress {
  box-shadow: 2px 2px 2px #888888;
  background: #e8e8e8;
  position: fixed;
  bottom: 15px;
  z-index: 5;
  width: 63%;
}

@media (max-width: 767px) {
  #myProgress {
    width: 80%;
  }
}
#myBar {
  width: 10%;
  height: 30px;
  background-color: #ee4b2b;
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: white;
}

.input_accordion {
  display: none;
}

.label_accordion {
  display: flex;
  width: 100%;
  height: 50px;
  cursor: pointer;
  border: 3px solid #3e474f;
  user-select: none;
}

.label_accordion div:first-child {
  width: 100%;
  line-height: 45px;
  margin-left: 10px;
  font-size: 1.2em;
}

.cross {
  margin-right: 15px;
  margin-top: 3px;
}

.cross:before,
.cross:after {
  content: "";
  border-top: 2px solid #3e474f;
  width: 15px;
  display: block;
  margin-top: 18px;
  transition: 0.3s;
}

.cross:after {
  transform: rotate(90deg);
  margin-top: -2px;
}

.content {
  box-sizing: border-box;
  font-size: 0.9em;
  margin: 5px 5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s;
}

.input_accordion:checked ~ .content {
  max-height: 2000px;
  transition: max-height, 1s;
}

.input_accordion:checked ~ label .cross:before {
  transform: rotate(180deg);
}

.input_accordion:checked ~ label .cross:after {
  transform: rotate(0deg);
}

.questions {
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s;
}

.questions label {
  border: none;
  box-shadow: none;
  margin: 0;
}

.input_accordion:checked ~ .questions {
  max-height: 2000px;
  border-bottom: 2px solid #3e474f;
  transition: 1s;
}

#a2zdsaNoteFeatures {
  margin-left: 20px;
}

#a2zdsaDetailsFeatures {
  background: #f7f8fa;
}

.gfgad {
  float: left;
  margin-left: 80px;
}

@media (max-width: 767px) {
  .gfgad {
    display: none;
  }
}
.logout-button.hide {
  display: none;
}

/* Auth Modal Styles */

.auth-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(
    149.45deg,
    rgba(255, 255, 255, 0.1) 14.25%,
    rgba(255, 255, 255, 0.4) 85.42%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.auth-modal.show {
  display: grid;
  place-items: center;
}

.auth-modal__container {
  padding: 5px 40px;
  border: 2px solid rgba(236, 55, 19, 0.5);
  width: 520px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.auth-modal__container .auth.hide {
  display: none;
}
.auth-modal__container .auth__close {
  background: none;
  border: none;
  position: absolute;
  top: 1e;
  right: 1em;
  cursor: pointer;
}

.auth-modal__container .auth__header {
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.auth-modal__container .auth__header h2 {
  color: #303030;
  font-weight: 500;
  font-size: 2rem;
  line-height: 32px;
  margin-bottom: 12px;
}
.auth-modal__container .auth__header img {
  width: 50px;
  height: 53px;
  position: relative;
  display: inline-block;
}

.auth-modal__container .auth__header p {
  color: #303030;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

.auth-modal__container .auth__form--field {
  margin-bottom: 15px;
}

.auth-modal__container .auth__form .fields-container {
  display: flex;
  gap: 1em;
}
.auth-modal__container .auth__form .fields-container .auth__form--field {
  flex: 1 0 calc(50% - 0.5em);
}

.auth-modal__container .auth__form--field input {
  background-color: #5e1608;
  height: 43px;
  border: none;
  outline: none;
  padding: 13px 17px;
  width: 100%;
  color: #ffffff;
}
.auth-modal__container .auth__form--field input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.auth-modal__container .auth__form--field input:-webkit-autofill,
.auth-modal__container .auth__form--field input:-webkit-autofill:hover,
.auth-modal__container .auth__form--field input:-webkit-autofill:focus,
.auth-modal__container .auth__form--field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #5e1608 inset !important;
  box-shadow: 0 0 0 30px #5e1608 inset !important;
}

.auth-modal__container .auth__form--field input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.auth-modal__container .auth__form--submit {
  margin-top: 25px;
}

.auth-modal__container .auth__form--submit button {
  background-color: #ec3713;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
}

.auth-modal__container .auth__form--google {
  margin-top: 20px;
}
.auth-modal__container .auth__form--google iframe {
  margin: 0 auto !important;
}

.auth-modal__container .auth__form--error {
  margin: 15px 0 30px 0;
}

.auth-modal__container .auth__form--error p {
  font-size: 14px;
  color: red;
  line-height: 18px;
  height: 18px;
}

.auth-modal__container .auth__footer p {
  text-align: center;
  color: #4f4f4f;
  font-weight: 500;
  font-size: 14px;
}

.auth-modal__container .auth__footer p button {
  color: #ec3713;
  text-decoration: underline;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .auth-modal__container {
    width: 90vw;
    padding: 5px 10px;
  }
}

.outer-box-tracker .global {
  padding: 20px 40px;
}

.logout-button.hide {
  display: none;
}

/* --- Back-Up CSS ----*/
.site {
  max-width: 1500px;
}

.site-content {
  padding: 0em 0 1em;
}

.more-link {
  background-color: #ee4b2b;
}

.pagination .current {
  background-color: #ee4b2b;
}

.es_subscription_form input[type="submit"] {
  background-color: #ee4b2b;
}

.wp-block-group a {
  color: #ee4b2b;
}

.wa_btn a {
  color: #ffffff;
}

.wa_share {
  text-decoration: none;
  background-color: #ee4b2b;
  border: none;
  color: #ffffff;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
}

#diqus_loader {
  margin-left: 40%;
}

@media (max-width: 767px) {
  #diqus_loader {
    margin-left: 19%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #diqus_loader {
    margin-left: 35%;
  }
}

#toc_container {
  background: #f9f9f9 none repeat scroll 0 0;
  border: 1px solid #aaa;
  display: table;
  font-size: 95%;
  margin-bottom: 1em;
  padding: 20px;
  width: auto;
}

.toc_title {
  font-weight: 700;
  text-align: center;
}

#toc_container li,
#toc_container ul,
#toc_container ul li {
  list-style: outside none none !important;
}

/*details[open]  {
    animation: open 2s ;
      animation-direction: normal;
  }
  
  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }*/

@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-3em));
  }
}

details[open] > *:not(summary) {
  animation: details-show 0.5s ease-in-out;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  /*border-top: 1px solid black;*/
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  font-size: 1.15rem;
  font-weight: 300;
  list-style: none;
  font-family: "IBM Plex Sans", sans-serif;
  transition: background 0.3s;
}

/*details[open] summary{
      background: #303030;
      color: #FFFFFF;
      border-bottom: 2px solid #EE4B2B;
      border-left: 2px solid #EE4B2B;
  }*/

div.entry-content > div#dsa_sheets_problems_table_row_details:not(.not-del) {
  display: none;
}
#dsa_sheets_problems_table_row_details
  #dsa_sheets_problems_table_row_summary:after {
  content: "+";
  color: black;
  position: absolute;
  font-size: 1.75rem;
  line-height: 0;
  right: 0.25em;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
#dsa_sheets_problems_table_row_details[open]
  #dsa_sheets_problems_table_row_details
  #dsa_sheets_problems_table_row_summary::after {
  margin-top: 0;
  margin-right: 5px;
  margin-left: 3px;
}

#dsa_sheets_problems_table_row_details[open]
  #dsa_sheets_problems_table_row_summary:after {
  transform: rotate(45deg);
  font-size: 2rem;
  color: #000;
}
#dsa_sheets_problems_table_row_details #dsa_sheets_problems_table_row_summary {
  outline: 0;
}
#dsa_sheets_problems_table_row_details p {
  font-size: 0.95rem;
  margin: 0 0 1rem;
  padding-top: 1rem;
}

.detailsTable {
  overflow-x: scroll;
}
/* Details Summary CSS */

.containerCard {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.card {
  box-shadow: 1px 1px 10px 1px #a1a1a1;
  border-radius: 10px;
  padding: 20px;
}

.main-profile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-info img {
  width: 100px;
  border-radius: 50%;
}

.user-info h3 {
  font-size: 1.3rem;
}

.user-info p {
  color: #5b7083;
}

.follow-btn a {
  font-size: 1.5rem;
  border: 3px solid #1da1f2;
  border-radius: 25px;
  background-color: rgba(29, 161, 242, 0);
  padding: 8px 20px;
  margin: 5px;
  font-weight: bold;
  color: #1da1f2;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.follow-btn a:hover {
  background-color: rgba(29, 161, 242, 0.2);
}

.user-bio {
  color: #0f1419;
  margin-bottom: 10px;
}

.user-bio a {
  color: #1da1f2;
  text-decoration: none;
}

.user-bio a:hover {
  text-decoration: underline;
}

.user-follows {
  display: flex;
  color: #5b7083;
}

.user-follows div {
  margin-right: 10px;
}

.user-follows b {
  color: black;
}

.navAds {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 2;
}

.navAds a {
  text-decoration: none;
  color: red;
  font-weight: bold;
}

/* Sticky Ads */
.sticky-ads {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  left: 50%;
  min-height: 70px;
  max-height: 200px;
  padding: 0px 0;
  box-shadow: 0 -6px 18px 0 rgba(9, 32, 76, 0.1);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  z-index: 20;
}

@media (max-width: 767px) {
  .sticky-ads {
    left: 0%;
    width: 100%;
  }
}

.sticky-ads-close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 0 0;
  position: absolute;
  right: 0;
  top: -30px;
  background-color: #fefefe;
  box-shadow: 0 -6px 18px 0 rgba(9, 32, 76, 0.08);
}

.sticky-ads .sticky-ads-close svg {
  width: 22px;
  height: 22px;
  fill: #000;
}
.sticky-ads .sticky-ads-content {
  overflow: hidden;
  display: block;
  position: relative;
  height: 70px;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

/*timeline*/
/*
  !!!!
  This pen is being refactored
  !!!!
  */

/*
  =====
  DEPENDENCES
  =====
  */

.r-title {
  margin-top: var(--rTitleMarginTop, 0) !important;
  margin-bottom: var(--rTitleMarginBottom, 0) !important;
}

p:not([class]):first-child {
  margin-top: 0;
}

/*
  text component
  */

.text {
  display: var(--textDisplay, inline-flex);
  font-size: var(--textFontSize, 1rem);
}

/*
  time component
  */

/*
  core styles
  */

.time {
  display: var(--timeDisplay, inline-flex);
}

/*
  extensions
  */

.time__month {
  margin-left: var(--timelineMounthMarginLeft, 0.25em);
}

/*
  skin
  */

.time {
  padding: var(--timePadding, 0.25rem 1.25rem 0.25rem);
  background-color: var(--timeBackgroundColor, #f0f0f0);

  font-size: var(--timeFontSize, 0.75rem);
  font-weight: var(--timeFontWeight, 700);
  text-transform: var(--timeTextTransform, uppercase);
  color: var(--timeColor, currentColor);
}

/*
  card component
  */

/*
  core styles
  */

.card {
  padding: var(--timelineCardPadding, 1.5rem 1.5rem 1.25rem);
}

.card__content {
  margin-top: var(--cardContentMarginTop, 0.5rem);
}

/*
  skin
  */

.card {
  border-radius: var(--timelineCardBorderRadius, 2px);
  border-left: var(--timelineCardBorderLeftWidth, 3px) solid
    var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
  box-shadow: var(
    --timelineCardBoxShadow,
    0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.24)
  );
  background-color: var(--timelineCardBackgroundColor, #fff);
}

/*
  extensions
  */

.card__title {
  --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
  font-size: var(--cardTitleFontSize, 1.25rem);
}

/*
  =====
  CORE STYLES
  =====
  */

.timeline {
  display: var(--timelineDisplay, grid);
  grid-row-gap: var(--timelineGroupsGap, 2rem);
}

/*
  1. If timeline__year isn't displaed the gap between it and timeline__cards isn't displayed too
  */

.timeline__year {
  margin-bottom: 1.25rem; /* 1 */
}

.timeline__cards {
  display: var(--timeloneCardsDisplay, grid);
  grid-row-gap: var(--timeloneCardsGap, 1.5rem);
}

/*
  =====
  SKIN
  =====
  */

.timeline {
  --uiTimelineMainColor: var(--timelineMainColor, #222);
  --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);

  border-left: var(--timelineLineWidth, 3px) solid
    var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.timeline__year {
  --timePadding: var(--timelineYearPadding, 0.5rem 1.5rem);
  --timeColor: var(--uiTimelineSecondaryColor);
  --timeBackgroundColor: var(--uiTimelineMainColor);
  --timeFontWeight: var(--timelineYearFontWeight, 400);
}

.timeline__card {
  position: relative;
  margin-left: var(--timelineCardLineGap, 1rem);
}

/*
  1. Stoping cut box shadow
  */

.timeline__cards {
  overflow: hidden;
  padding-top: 0.25rem; /* 1 */
  padding-bottom: 0.25rem; /* 1 */
}

.timeline__card::before {
  content: "";
  width: 100%;
  height: var(--timelineCardLineWidth, 2px);
  background-color: var(
    --timelineCardLineBackgroundColor,
    var(--uiTimelineMainColor)
  );

  position: absolute;
  top: var(--timelineCardLineTop, 1rem);
  left: -50%;
  z-index: -1;
}

/*
  =====
  SETTINGS
  =====
  */
/**/
.timeline {
  --timelineMainColor: #4557bb;
}

/*
  =====
  DEMO
  =====
  */

.page {
  max-width: 47rem;
  padding: 5rem 2rem 3rem;
  margin-left: auto;
  margin-right: auto;
}

.substack {
  border: 1px solid #eee;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  height: 280px;
  margin: 1rem auto;
}

.linktr {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
}

#myProgress {
  box-shadow: 2px 2px 2px #888888;
  background: #e8e8e8;
  position: fixed;
  bottom: 15px;
  z-index: 5;
  width: 63%;
}

@media (max-width: 767px) {
  #myProgress {
    width: 80%;
  }
}

#myBar {
  width: 10%;
  height: 30px;
  background-color: #ee4b2b;
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: white;
}

/* @media (max-width: 767px) {
      details table{
          overflow-x: auto;
      }
  } */

.input_accordion {
  display: none;
}

.label_accordion {
  display: flex;
  width: 100%;
  height: 50px;
  cursor: pointer;
  border: 3px solid #3e474f;
  user-select: none;
}

.label_accordion div:first-child {
  width: 100%;
  line-height: 45px;
  margin-left: 10px;
  font-size: 1.2em;
}

.cross {
  margin-right: 15px;
  margin-top: 3px;
}

.cross:before,
.cross:after {
  content: "";
  border-top: 2px solid #3e474f;
  width: 15px;
  display: block;
  margin-top: 18px;
  transition: 0.3s;
}

.cross:after {
  transform: rotate(90deg);
  margin-top: -2px;
}

.content {
  box-sizing: border-box;
  font-size: 0.9em;
  margin: 5px 5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s;
}

.input_accordion:checked ~ .content {
  max-height: 2000px;
  transition: max-height, 1s;
}

.input_accordion:checked ~ label .cross:before {
  transform: rotate(180deg);
}

.input_accordion:checked ~ label .cross:after {
  transform: rotate(0deg);
}

.questions {
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s;
}

.questions label {
  border: none;
  box-shadow: none;
  margin: 0;
}

.input_accordion:checked ~ .questions {
  max-height: 2000px;
  border-bottom: 2px solid #3e474f;
  transition: 1s;
}

.entry-content > details > summary {
  /* 	margin-left: 20px;  */
  /*  	margin-right:20px;   */
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 0.75em;
  align-items: center;
  padding-right: 1em;
}

details details summary {
  /* 	margin-left: 20px; 
      margin-right:20px;  */
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 0.75em;
  align-items: center;
  padding-right: 1em;
}

details[open] details[closed] summary:after {
  transform: rotate(90deg);
  color: black;
}
details[open] details[open] summary:after {
  transform: rotate(45deg);
}

details details p {
  margin-left: 5px;
  font-size: large;
}
details details table {
  margin-left: 5px;
  /* 	margin-right: 5px; */
}
#a2zdsaNoteFeatures {
  margin-left: 20px;
}
#a2zdsaDetailsFeatures {
  background: #f7f8fa;
}

.entry-content > .outer-box-tracker {
  padding: 5px 10px 5px 10px;
  width: 100%;
  margin-left: 0px;
  margin-top: 15px;
  margin-bottom: 30px;
  position: sticky;
  top: 2.8em;
  padding-right: 10px;
  box-shadow: #e8e8e8 0 3px 3px;
  background-color: white;
  z-index: 3;
  border: 1px solid #ee4b2b25;
}
.entry-content > .outer-box-tracker .text {
  font-size: 1.1em;
  /* 	margin-right: 0.5em; */
  /* 	padding-right:10px; */
}
.entry-content > .outer-box-tracker .box-of-tracker {
  width: calc(100% - 87px);
  height: 18px;
  display: inline-block;
  background-color: #e8e8e8;
  overflow: hidden;
  /* 	border-width:1px;
      border-style:solid;
      border-color:#EE4B2B; */
}

.entry-content > details > summary .box-of-tracker {
  width: 200px;
  height: 13px;
  display: inline-block;
  background-color: #e8e8e8;
  overflow: hidden;
}

.entry-content > details > details > summary .box-of-tracker {
  width: 150px;
  height: 10px;
  display: inline-block;
  background-color: #e8e8e8;
  overflow: hidden;
}

.inner-filling {
  width: 0%;
  height: 100%;
  background-color: #ee4b2b !important;
}
.row-selected {
  background-color: #90ee9040;
}

/* .table-box{
        border-width: 1px;
      border-style: solid;
      border-color: lightgreen;
        border-radius:5px;	
  } */

/* //top bar// */
.entry-content > details:hover > summary {
  background-color: #e8e8e8;
  /* 	border-width:10px;
      border-style:solid;
      border-color:black; */
}
.entry-content > details > summary {
  margin-bottom: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #ee4b2b25;
}
/* //top bar// */

/* //2nd top bar // */
.entry-content > details > details:hover > summary {
  background-color: #e8e8e8;
}
.entry-content > details > details > summary {
  margin-bottom: 10px;
  box-shadow: #ee4b2b15 0 3px 3px !important;
}
/* //2nd top bar // */

/* //top body// */
.entry-content > details > details > table tbody tr:not(.table-top):hover {
  background-color: #90ee9040;
}

/*  topp bar for alignment*/
.entry-content > details > summary {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  padding-right: 1em;
}

/* details{
      overflow:auto !important;
  } */

details details {
  margin-left: 20px;
  margin-right: 0px;
}

@media (max-width: 767px) {
  details {
    overflow: auto;
  }
}

.gfgad {
  float: left;
  margin-left: 80px;
}

@media (max-width: 767px) {
  .gfgad {
    display: none;
  }
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_above_sheets {
  background-color: #e8e8e8;
  color: #f0f0f0;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: rectangle;
  outline: none;
  transition: 0.4s;
}
.accordion_above_sheets:hover {
  background-color: #90ee9040;
}

.accordion_above_sheets:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #f0f0f0;
  float: right;
  margin-left: 5px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion_above_sheets:hover {
  background-color: #f0f0f0;
}

/* Style the accordion panel. Note: hidden by default */
.accordion_above_sheets_panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

b,
strong {
  font-weight: bold !important;
}

.wp-block-preformatted {
  white-space: pre-wrap;
}
.atricle_example_content {
  @apply px-5;
  overflow: auto;
  max-width: 100%;
  /* border-top: 1px solid rgba(0, 0, 0, 0.15); */
  background: 0 0;
  font-size: 15px;
  font-family: "courier 10 pitch", Courier, monospace;
}
.atricle_example_content .wp-block-preformatted {
  @apply flex items-start justify-start flex-col gap-y-4;
}
.atricle_example_content .wp-block-preformatted .wp-block-image {
  margin-top: 4px;
  margin-bottom: 4px;
}
/*Don't Change Or Delete This*/
#article_examples {
  margin-top: 20px;
}

.article_example_summary {
  padding: 4px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  font-family: titillium web, arial, helvetica, sans-serif;
  align-items: center;
  font-weight: 600;
  list-style: none;
  transition: background 0.3s;
  font-size: 20px;
}

.article_example_summary .arrow-svg {
  width: 35px;
  height: 35px;
  margin-left: auto;
  transition: all 0.3s;
  fill: #ee4b2b;
}

.article_example_details .article_example_summary .arrow-svg {
  transform: rotate(90deg);
}

.article_example_details[open] .article_example_summary .arrow-svg {
  transform: rotate(-90deg);
}

#article_examples .article_example_details {
  @apply border border-zinc-200 dark:border-zinc-700 bg-white dark:bg-dark_50 p-2 rounded-lg shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
}

.horizontal_navbar_dsa_article {
  display: none;
  position: sticky;
  margin-left: 0;
  top: 3.9em;
  z-index: 3;
}
.grid_container_three {
  @apply border sticky border-zinc-200 dark:border-zinc-700 p-[10px]  rounded-lg mt-7 bg-white dark:bg-dark_50 text-gray-700 dark:text-gray-300 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
  position: sticky;
  z-index: 3;
  display: grid;
  grid-template-columns: 27% 27% 27% 12% 7%;
}

.grid_container_two {
  display: grid;
  grid-template-columns: 40.5% 40.5% 12% 7%;
  @apply border border-zinc-200 dark:border-zinc-700 p-[10px]  rounded-lg mt-7 bg-white dark:bg-dark_50 text-gray-700 dark:text-gray-300 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
  position: sticky;
}

.grid_container_four {
  display: grid;
  grid-template-columns: 20.25% 20.25% 20.25% 20.25% 12% 7%;
  @apply border border-zinc-200 dark:border-zinc-700 p-[10px]  rounded-lg mt-7 bg-white dark:bg-dark_50  text-gray-700 dark:text-gray-300 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
  position: sticky;
}

.grid_container_three a,
.grid_container_two a,
.grid_container_four a {
  @apply text-gray-800 dark:text-gray-200;
}

.grid_container_three a:focus,
.grid_container_two a:focus,
.grid_container_four a:focus {
  outline: none;
}

.three-boxes,
.two-boxes {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: background-color 0.3s ease-out;
  color: #000;
  font-weight: 600;
}

.four-boxes {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  transition: background-color 0.3s ease-out;
  color: #000;
  font-weight: 600;
}

.box-a.actively,
.box-b.actively,
.box-c.actively,
.box-c-2.actively {
  color: #fff;
  padding: 10px 10px;
  transition-duration: 500ms;
  background-color: #ee4c2bc5;
  text-decoration: none;
}

.box-a,
.box-b,
.box-c,
.box-c-2,
.box-d {
  @apply border-r border-zinc-200 dark:border-zinc-700 underline;
}

.box-e {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.tooltip {
  position: relative;
  text-align: left;
}

.tooltip svg {
  width: 20px;
  height: 20px;
}

.tooltip svg:hover {
  cursor: pointer;
}

.tooltip .bottom {
  min-width: 380px;
  top: 65px;
  right: 100%;
  transform: translate(18%, 0);
  padding: 5px 10px;
  color: #fff;
  background-color: #ee4b2b;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip:hover .bottom {
  margin-left: -120px;
  visibility: visible;
  opacity: 1;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 88%;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #ee4b2b;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.dsa_article_youtube_video {
  margin-top: 40px;
}

.yt-video-wrapper {
  padding: 2em;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
  align-items: center;
}

.dsa_article_dropdown_approachs {
  margin-top: 40px;
}

details .main-summary svg {
  transform: rotate(90deg);
}

details[open] .main-summary svg {
  transform: rotate(-90deg);
}

.main-summary svg {
  width: 35px;
  height: 35px;
  margin-left: auto;
  transition: all 0.3s;
  fill: #ee4b2b;
}

.main-summary {
  @apply border border-zinc-200 dark:border-zinc-700 bg-white dark:bg-dark_50 p-2 rounded-lg shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  margin-top: 15px;
  align-items: center;
  font-weight: 600;
  list-style: none;
  transition: background 0.3s;
  font-size: 19px;
}

.approach-details ul {
  list-style: none;
}

.main-summary span {
  margin-left: 10px;
}

details[open] .main-summary {
  box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
}

.code-tabs {
  @apply bg-white border-t border-zinc-300 dark:border-zinc-800 dark:bg-dark_50;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.code-tab {
  @apply bg-white dark:bg-dark border border-zinc-300 dark:border-zinc-800 p-4 border-b-0 border-t-0;
}

.code-tabs .code-tab:last-child {
  @apply border-l-0;
}
.code-tab.dsa_article_code_active {
  @apply bg-[#fafafa] dark:bg-dark_50 text-brand  border-t-brand border-b-0;
  transition: all 0.3s ease;
  font-weight: 600;
}

.code-block {
  display: none;
}

/* .code-block pre code {
  color: #abb2bf;
} */

.code-block.dsa_article_code_active {
  display: block;
}

.copy-button {
  @apply flex justify-center items-center py-0.5 px-3 rounded-lg border-brand_20 bg-[#FDEDEA] dark:bg-[#ee4c2b39] dark:border-[#ee4c2b88] border-2 text-[16px] text-brand ml-2;
}

.code-block code {
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
}

.code-block pre.wp-block-code {
  border-radius: 8px;
}

.common-drops {
  @apply dark:bg-dark_50 dark:shadow-none;
  padding: 14px 0px;
  box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
  padding-bottom: 20px;
}

.common-drops details {
  @apply border-b dark:border-zinc-700;
  margin-right: 34px;
  margin-bottom: 10px;
}

.approach-algorithm h3 {
  @apply mt-4;
}

.approach-algorithm ol,
ul,
menu {
  list-style: decimal;
  margin-left: 40px;
  padding: 10px;
}
.approach-algorithm ol li {
  @apply mt-1;
}
.practice {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.practice span {
  padding: 4px;
  font-size: 22px;
}
.problem-buttons a {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 14px;
  background-color: rgb(255, 255, 255);
  border: 2px solid #ee4c2b99;
  border-radius: 70px;
  margin-left: 20px;
}
.problem-buttons a span {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #ee4b2b;
}
.problem-buttons a img {
  height: 20px;
  width: 20px;
}
@media (max-width: 480px) {
  .grid_container_three a,
  .grid_container_two a {
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .grid_container_four {
    font-size: 10px;
  }

  .common-drops details {
    margin-right: 10px;
  }

  .tooltip .bottom {
    min-width: 200px;
    font-size: 10px;
    border-radius: 0;
    transform: translate(16%, 0);
  }

  .yt-video-wrapper iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .common-drops {
    padding: 6px;
    box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
    padding-bottom: 18px;
  }
  .problem-buttons a span {
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
    color: #ee4b2b;
  }
  .problem-buttons a {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;

    background-color: rgb(255, 255, 255);
    border: 2px solid #ee4c2b99;
  }
  .problem-buttons a img {
    height: 14px;
    width: 14px;
  }

  .practice span {
    padding: 4px;
    font-size: 17px;
  }
}

@media (max-width: 780px) {
  .grid_container_three a,
  .grid_container_two a {
    font-size: 17px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 18px;
  }

  .grid_container_four a {
    font-size: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 18px;
  }

  .yt-video-wrapper iframe {
    top: 0;
    left: 0;
    width: 100%;
  }

  .tooltip .bottom {
    font-size: 12px;
    transform: translate(22%, 0);
  }

  .common-drops {
    padding: 6px;
    box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
    padding-bottom: 18px;
  }
}

.common-drops {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.secondary-summary {
  padding: 14px 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-family: titillium web, arial, helvetica, sans-serif;
  align-items: center;
  font-weight: 600;
  list-style: none;
  transition: background 0.3s;
}

.secondary-summary .arrow-svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
  transition: all 0.3s;
  fill: #ee4b2b;
}

.secondary-details .secondary-summary .arrow-svg {
  transform: rotate(90deg);
}

.secondary-details[open] .secondary-summary .arrow-svg {
  transform: rotate(-90deg);
}
.new-disclaimer {
  color: red;
  font-size: 16px;
  margin-top: 14px;
}
.overview {
  margin-top: 30px;
}

.overview-summary {
  padding: 14px 14px 10px 14px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #2d2d2d2f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  list-style: none;
  transition: background 0.3s;
  font-size: 22px;
}

.overview-details {
  padding: 20px;
}

.overview-summary svg {
  transform: rotate(90deg);
  margin-left: auto;
  width: 35px;
  height: 35px;
}

.overview-summary.overview-active svg {
  transform: rotate(-90deg);
  transition-duration: 500ms;
}

.overview-summary svg {
  transform: rotate(90deg);
  transition-duration: 500ms;
}

blockquote {
  @apply pl-[1.25em] border-l-4 border-zinc-800 bg-white p-4 dark:bg-dark_50;
  margin: 1.5em 0;
  font-style: normal;
  font-size: 18px;
  font-size: 1.125rem;
}

.overview-summary.overview-active {
  border: none;
}

.overview-details p {
  font-size: 18px;
}

.overview-details {
  display: none;
}

.overview-details.overview-active {
  display: block;
}

.overview.overview-active {
  box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
  border-radius: 8px;
}

.sde-sheet-overview-content {
  padding: 0px 14px 14px 14px;
}
.sde-sheet-overview-content h4 {
  color: #ee4b2b;
  text-decoration: underline;
}
.sde-sheet-overview-content a {
  text-decoration: none;
}
.sde-sheet-overview {
  margin-bottom: 30px;
}
.overview {
  margin-bottom: 50px;
}
.sde-sheet-overview-content p .highlight-faq {
  color: #ee4c2b;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  font-size: 19px;
  margin-right: 4px;
}
.instructor-details {
  display: flex;
  justify-content: space-around;
}
.instructor-details img {
  width: 210px;
  height: 210px;
  margin-top: 20px;
  object-fit: contain;
}
.faq {
  margin-top: 30px;
}

.faq-summary {
  padding: 14px 14px 10px 14px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #2d2d2d2f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  list-style: none;
  transition: background 0.3s;
  font-size: 18px;
}

.faq-details {
  padding: 20px;
}

.faq-summary svg {
  transform: rotate(90deg);
  margin-left: auto;
  width: 35px;
  height: 35px;
}

.faq-summary.faq-active svg {
  transform: rotate(-90deg);
  transition-duration: 500ms;
}

.faq-summary svg {
  transform: rotate(90deg);
  transition-duration: 500ms;
}

.faq-summary.faq-active {
  border: none;
}

.faq-details p {
  font-size: 18px;
}

.faq-details {
  display: none;
}

.faq-details.faq-active {
  display: block;
}

.faq.faq-active {
  box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29;
  border-radius: 8px;
}
.faqs {
  margin-top: 15px;
}
.faqs .faq-heading span {
  font-size: 22px;
  text-decoration: underline;
  font-weight: 600;
}

@media (max-width: 480px) {
  .instructor-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .instructor-details img {
    width: 150px;
    height: 150px;
    margin-top: 20px;
    object-fit: contain;
  }
}
.highlight-main {
  color: #ee4b2b;
}

.prizes-won ul li {
  background-color: #f69f8e55;
  margin-top: 15px;
  padding: 10px;
  border-radius: 8px;
}
.prizes-won h3 {
  text-decoration: underline;
}

/* --------------------------*/

/* navbar additional modification */
.main-navigation .menu-item,
.header__content--branding {
  font-family: "Amaranth", sans-serif !important;
  font-weight: regular !important;
}
.header__content {
  padding: 0px 5vw !important;
  height: 50px;
}
.menu-item {
  margin-right: 0px !important;
}

.navAds {
  box-shadow: rgba(0, 0, 0, 0) 0 10px 15px;
  border-bottom: 1px solid #00000010;
}
.header__content--widgets {
  display: none;
}

.header-auth-dark-mode {
  display: flex;
}

.header-auth-dark-mode .header__content--auth {
  margin-left: 20px;
}
.header-auth-dark-mode {
  margin-top: 15px;
}
@media (max-width: 600px) {
  .header__content {
    height: auto;
  }
  #main-navigation-wrap {
    padding: 0;
  }
}

/*------ Sidebar-----*/

.sidebar-company-tags {
  padding: 12px;
  text-align: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.sidebar-company-tags span {
  font-weight: 600;
  font-size: 16px;
}

.sidebar-company {
  padding: 10px;
  margin-top: 12px;
  border-top: 1px solid rgba(82, 82, 82, 0.768);
}

.sidebar-company a {
  display: flex;
  color: #ee4b2b;
  font-size: 16px;
  text-decoration: none;
  border: 2px solid #ee4c2b99;
  border-radius: 50px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 12px;
  padding-left: 12px;
  font-weight: 600;
  margin-top: 14px;
  margin-top: 20px;
  white-space: nowrap;
  width: fit-content;
}

.sidebar-company {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 10px;
}

.sidebar-company a {
  text-align: center;
  margin: 0;
}

.sidebar-yt-video-wrapper iframe {
  width: 100%;
  height: auto;
}
.sidebar-yt-video-wrapper {
  padding: 14px;
}

/*----- Footer -------*/

.dark .new-site-footer {
  background-color: #212121;
}

.dark .footer-dsa-playlist a,
.dark .footer-contribute a,
.dark .footer-top-interv-ques a,
.dark .footer-dsa-sheets a {
  color: white;
}

.new-site-footer {
  padding: 24px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
}

.footer-tuf-quote {
  line-height: 14px;
}

.footer-tuf-quote span {
  font-size: 13px;
}

.footer-top-left {
  display: flex;
  align-items: center;
}

.footer-top-left svg {
  width: 28px;
  height: 28px;
}

.footer-heading {
  font-size: 34px;
  margin-left: 10px;
  font-family: "Amaranth";
  font-weight: 900;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-socials-follow {
  display: flex;
  margin-top: 5px;
}

.twitter,
.facebook,
.linkedin,
.instagram,
.telegram {
  margin-left: 18px;
}

.follow-us {
  font-weight: 600;
  margin-top: 30px;
}

.footer-dsa-playlist,
.footer-contribute,
.footer-top-interv-ques,
.footer-dsa-sheets {
  display: flex;
  flex-direction: column;
}

.footer-dsa-playlist span,
.footer-contribute span,
.footer-top-interv-ques span,
.footer-dsa-sheets span {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
}

.footer-dsa-playlist a,
.footer-contribute a,
.footer-top-interv-ques a,
.footer-dsa-sheets a {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}

.footer-dsa-playlist,
.footer-top-interv-ques,
.footer-dsa-sheets {
  margin-right: 80px;
}

.footer-links-content {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

.footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcdfda;
  padding: 4px;
}

.footer-copyright span {
  font: 16px;
  color: #ee4b2b;
  font-weight: 600;
}

@media (max-width: 780px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-links-content {
    display: flex;
    margin-left: 0px;
    justify-content: space-between;
    margin-top: 10px;
  }

  .footer-dsa-playlist,
  .footer-contribute,
  .footer-top-interv-ques,
  .footer-dsa-sheets {
    justify-content: center;
    align-items: center;
  }

  .footer-dsa-playlist {
    margin-right: 30px;
  }

  .footer-dsa-playlist span,
  .footer-contribute span,
  .footer-top-interv-ques span,
  .footer-dsa-sheets span {
    font-size: 15px;
  }

  .footer-dsa-playlist a,
  .footer-contribute a,
  .footer-top-interv-ques a,
  .footer-dsa-sheets a {
    font-size: 13px;
  }

  .footer-dsa-playlist,
  .footer-top-interv-ques,
  .footer-dsa-sheets {
    margin-right: 20px;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcdfda;
    padding: 4px;
  }

  .footer-copyright span {
    font-size: 14px;
    font-weight: 600;
  }

  .footer-heading {
    font-size: 28px;
    margin-left: 7px;
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-top-left svg {
    width: 20px;
    height: 20px;
  }

  .footer-tuf-quote span {
    font-size: 14px;
  }

  .follow-us {
    font-weight: 600;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-links-content {
    display: flex;
    margin-left: 0px;
    flex-direction: column;
    align-items: center;
  }

  .footer-dsa-playlist,
  .footer-contribute,
  .footer-top-interv-ques,
  .footer-dsa-sheets {
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .footer-dsa-playlist,
  .footer-top-interv-ques,
  .footer-dsa-sheets {
    margin-right: 0px;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcdfda;
    padding: 4px;
  }

  .footer-copyright span {
    font-size: 14px;
    font-weight: 600;
  }

  .footer-heading {
    font-size: 24px;
    margin-left: 7px;
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-top-left svg {
    width: 18px;
    height: 18px;
  }

  .footer-tuf-quote span {
    font-size: 11px;
  }

  .follow-us {
    font-weight: 600;
    margin-top: 20px;
  }
}

/*---- Dark Mode ----*/
body.dark {
  color: #f3f3f3ea;
}
.dark .site {
  background-color: #1a1a1a;
}
.dark-mode {
  display: none;
  cursor: pointer;
}

.light-mode {
  cursor: pointer;
}

.dark .entry-title {
  color: #f3f3f3ea;
}
.dark .entry-header h2 a {
  color: #f3f3f3ea;
}
.dark .navAds {
  background-color: #212121;
}

.dark .navbar {
  background-color: #212121;
  color: #f3f3f3ea;
}

.dark .navbar .menu-item a {
  color: #f3f3f3ea;
}

.dark .entry-header .entry-meta span a {
  color: #f3f3f3ea;
}
.dark .outer-box-tracker.global {
  background: #212121;
  border: 1px solid #1a1a1a9a;
}
.dark details.top-level > summary::after {
  color: #f3f3f3ea;
}
.dark .top-level summary b {
  color: #f3f3f3ea;
}
.dark .outer-box-tracker .box-of-tracker {
  background-color: #1a1a1a9a;
}

.dark details.top-level > table {
  background-color: #212121;
}

.dark details.top-level > table thead th {
  background-color: #1a1a1a9a;
  color: #f3f3f3ea;
}

.dark details.top-level > table tbody tr td {
  background-color: #1a1a1a9a;
  color: #f3f3f3ea;
}

.dark details.top-level > table tbody tr td select.complete,
.dark details.top-level > table tbody tr td select.revision {
  color: #f3f3f3ea;
}

.dark details.top-level > table tbody tr td select {
  background: #1a1a1a;
  color: #f3f3f3ea;
}

.dark .sidebar .widget-header .widget-title {
  color: #f3f3f3ea;
}
.dark input {
  color: #f3f3f3ea;
  background-color: #212121;
}

.dark .wp-block-quote p,
.dark .wp-block-quote cite {
  color: #f3f3f3ea;
}

.wp-block-quote p em a {
  color: #ee4b2b;
  text-decoration: underline;
}
.wp-block-quote p a {
  color: #ee4b2b;
  text-decoration: underline;
}
.wp-block-quote strong a {
  color: #ee4b2b;
  text-decoration: underline;
}

.dark .navigation .nav-links a span {
  color: #f3f3f3ea;
}

.dark .tabcontent {
  background-color: #212121;
}

/* .dark .article_example_details {
  } */

.dark .horizontal_navbar_dsa_article {
  box-shadow: none;
  @apply rounded-lg shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px];
  background-color: #212121;
}
.dark .horizontal_navbar_dsa_article a {
  color: #f3f3f3ea;
}

.dark .box-a,
.dark .box-b,
.dark .box-c,
.dark .box-c-2,
.dark .box-d {
  border-right: 2px solid #6c6969;
}
.dark .grid_container_three,
.dark .grid_container_two,
.dark .grid_container_four {
  border: 1px solid #4b4b4b;
}

.dark .problem-buttons a {
  background-color: #212121;
}

/* .dark .main-summary {
    
    background-color: #212121;
  }
  .dark .main-summary .common-drops {
    background: #212121;
  } */

.copy-btn {
  @apply dark:bg-[#212121] bg-[#fafafa] stroke-zinc-700 dark:stroke-[#fafafa] p-4 border-l border-r border-l-zinc-300 dark:border-l-zinc-800 border-r-zinc-300 dark:border-r-zinc-800;
}

.dark .horizontal_navbar_dsa_article .tooltip svg path {
  fill: #f3f3f3ea;
}

.dark
  #dsa_sheets_problems_table_row_details
  #dsa_sheets_problems_table_row_summary:after {
  color: #f3f3f3ea;
}

.dark .timeline__card {
  background-color: #212121;
}

.dark .time {
  background-color: #1a1a1a;
}

.dark .outer-box-tracker {
  background-color: #212121;
  box-shadow: none;
  border: 1px solid #878787;
}
.dark .main-navigation .menu-item-has-children > a > .icon {
  fill: #f3f3f3ea;
}

.dark .notes-modal .notes__header--title {
  color: #f3f3f3ea;
}

.dark .notes-modal .notes {
  background-color: #212121;
}

.dark .notes-modal .notes__edit textarea {
  border: 1px solid #535252;
}

.dark .yt-modal .yt-sol {
  background-color: #212121;
}

.dark .faq-details,
.dark .faq-summary {
  background-color: #212121;
}
.dark .overview-details,
.dark .overview-summary {
  background-color: #212121;
}
.dark .entry-meta span::after {
  color: #f3f3f3ea;
}

/* .dark details:hover summary {
    background: none;
  }
  .dark .entry-content > details > details:hover > summary {
    background: none;
  } */

/* .dark thead,
.dark tbody {
  background-color: #212121;
} */

.dark details.top-level details table {
  background-color: #212121;
}

.dark details.top-level details table thead th {
  background-color: #1a1a1a9a;
  color: #f3f3f3ea;
}

.dark details.top-level details table tbody tr td {
  background-color: #1a1a1a9a;
  color: #f3f3f3ea;
}

.dark details.top-level details table tbody tr td select {
  background: #1a1a1a;
  color: #f3f3f3ea;
}

.dark details[open] details[closed] summary:after {
  color: #f3f3f3ea;
}

.dark .wp-block-search .wp-block-search__input {
  color: #f3f3f3ea;
}
.dark .footer-wrap {
  border-color: #f3f3f3ea;
}
.dark .post-navigation {
  border-color: #f3f3f3ea;
}
.entry-tags .meta-tags a {
  margin-top: 20px;
}
.dark .entry-tags .meta-tags a {
  border-color: #f3f3f3ea;
  color: #f3f3f3ea;
  margin-top: 20px;
}

.dark .entry-tags .meta-tags a:link {
  color: #f3f3f3ea;
}

.dark .wp-block-quote p em {
  color: #f3f3f3ea;
}

.dark details.top-level > table tbody tr td button img {
  filter: invert(15%);
}

#new-loader {
  width: 100%;
  margin: 0 auto;
}

.loading {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
}
.dark .loading {
  background: #212121;
}

.loading-shimmer {
  cursor: pointer;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(240, 95, 66, 0.068) 0%,
    rgba(242, 115, 90, 0.017) 20%,
    rgba(242, 115, 90, 0.017) 40%,
    rgba(242, 115, 90, 0.017) 100%
  );
  box-shadow: 0 1px 5px 0.5px rgba(236, 55, 19, 0.12);
  backdrop-filter: blur(20px);
  background-repeat: no-repeat;
  /* background-size: 800px 104px; */
  height: 40px;
  position: relative;
}

.loading-shimmer div {
  background: #ffffff;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -600px 0;
  }

  100% {
    background-position: 608px 0;
  }
}

@-webkit-keyframes prideShimmer {
  from {
    background-position: top left;
  }

  to {
    background-position: top right;
  }
}

/* Popup window add disable css*/

.adsbygoogle .adsbygoogle-noablate {
  display: none !important;
}

.merch-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*     align-items: center; */
  background-color: #ee4b2b;
  outline: none;
  width: fit-content;
  padding: 2px;
  padding-right: 10px;
  padding-left: 10px;
  color: white;
  border-radius: 10px;
  border: 0;
}
.merch-button .visit {
  font-size: 14px;
  align-items: left;
  font-family: titillium web, arial, helvetica, sans-serif;
  color: white;
}
.merch-button .support {
  font-size: 16px;
  margin-top: -8px;
  font-weight: 600;
}
.merch-link {
  margin-left: 10px;
}
.merch-link a {
  text-decoration: none;
  color: white;
}
.merch-link a:hover,
.merch-link a:active {
  color: white;
}

.new-button {
  background: #f3856f80;
  border: 2px solid #f3856f;
  color: #ee4b2b;
  font-weight: 600;
  font-size: 17px;
  border-radius: 10px;
}
.new-button:hover {
  background: #f3856f80;
  color: #ee4b2b;
}

@media only screen and (max-width: 50em) {
  .merch-button .visit {
    font-size: 12px;
    font-weight: 600;
    font-family: titillium web, arial, helvetica, sans-serif;
    color: white;
  }
  .merch-button .support {
    font-size: 10px;
    margin-top: -5px;
  }
}

/* Merch Side bar Card*/

.merchanside_card_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-family: "Titillium Web";
}

.merch_card {
  width: 14rem;
  height: 24rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  padding: 4px;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.3);
}

.merch_card img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 20rem;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
}

.merch_card h2 {
  position: absolute;
  inset: auto auto 30px 30px;
  margin: 0;
  transition: inset 0.3s 0.3s ease-out;
  font-family: "Titillium Web";
  font-weight: normal;
  text-transform: uppercase;
}

.merch_card p {
  position: absolute;
  color: white;
  background-color: #ee4b2b;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
  font-size: 17px;
  max-width: 80%;
  transition: opacity 0.3s ease-out;
}

.merch_card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 347px;
}

.dark .merch_card {
  box-shadow: 0 2px 10px 1px rgba(255, 255, 255, 0.3);
}

.system-design h3 {
  text-decoration: underline;
}
.system-design {
  margin-bottom: 5px;
}

.system-design-caution {
  color: #ee4b2b;
}

.system-design-uses {
  margin-top: 5px;
  margin-bottom: 2px;
  margin-left: 20px;
}

.dark .responsive-tabs .responsive-tabs__panel {
  background: #212121;
}

.dark .responsive-tabs .responsive-tabs__list__item--active {
  background: #212121;
  color: #fff;
}

.wp-block-quote {
  margin-top: 40px;
}

.wp-block-quote p em a {
  color: #ee4b2b;
}

/* Selection */
code[class*="language-"]::-moz-selection,
code[class*="language-"] *::-moz-selection,
pre[class*="language-"] *::-moz-selection {
  background: hsl(220, 13%, 28%);
  color: inherit;
  text-shadow: none;
}

code[class*="language-"]::selection,
code[class*="language-"] *::selection,
pre[class*="language-"] *::selection {
  background: hsl(220, 13%, 28%);
  color: inherit;
  text-shadow: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.2em 0.3em;
  border-radius: 0.3em;
  white-space: normal;
}

/* Print */
@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

.token.comment,
.token.prolog,
.token.cdata {
  color: hsl(220, 10%, 40%);
}

.token.doctype,
.token.punctuation,
.token.entity {
  color: hsl(220, 14%, 71%);
}

.token.attr-name,
.token.class-name,
.token.boolean,
.token.constant,
.token.number,
.token.atrule {
  color: hsl(29, 54%, 61%);
}

.token.keyword {
  color: hsl(286, 60%, 67%);
}

.token.property,
.token.tag,
.token.symbol,
.token.deleted,
.token.important {
  color: hsl(355, 65%, 65%);
}

.token.selector,
.token.string,
.token.char,
.token.builtin,
.token.inserted,
.token.regex,
.token.attr-value,
.token.attr-value > .token.punctuation {
  color: hsl(95, 38%, 62%);
}

.token.variable,
.token.operator,
.token.function {
  color: hsl(207, 82%, 66%);
}

.token.url {
  color: hsl(187, 47%, 55%);
}

/* HTML overrides */
.token.attr-value > .token.punctuation.attr-equals,
.token.special-attr > .token.attr-value > .token.value.css {
  color: hsl(220, 14%, 71%);
}

/* CSS overrides */
.language-css .token.selector {
  color: hsl(355, 65%, 65%);
}

.language-css .token.property {
  color: hsl(220, 14%, 71%);
}

.language-css .token.function,
.language-css .token.url > .token.function {
  color: hsl(187, 47%, 55%);
}

.language-css .token.url > .token.string.url {
  color: hsl(95, 38%, 62%);
}

.language-css .token.important,
.language-css .token.atrule .token.rule {
  color: hsl(286, 60%, 67%);
}

/* JS overrides */
.language-javascript .token.operator {
  color: hsl(286, 60%, 67%);
}

.language-javascript
  .token.template-string
  > .token.interpolation
  > .token.interpolation-punctuation.punctuation {
  color: hsl(5, 48%, 51%);
}

/* JSON overrides */
.language-json .token.operator {
  color: hsl(220, 14%, 71%);
}

.language-json .token.null.keyword {
  color: hsl(29, 54%, 61%);
}

/* MD overrides */
.language-markdown .token.url,
.language-markdown .token.url > .token.operator,
.language-markdown .token.url-reference.url > .token.string {
  color: hsl(220, 14%, 71%);
}

.language-markdown .token.url > .token.content {
  color: hsl(207, 82%, 66%);
}

.language-markdown .token.url > .token.url,
.language-markdown .token.url-reference.url {
  color: hsl(187, 47%, 55%);
}

.language-markdown .token.blockquote.punctuation,
.language-markdown .token.hr.punctuation {
  color: hsl(220, 10%, 40%);
  font-style: italic;
}

.language-markdown .token.code-snippet {
  color: hsl(95, 38%, 62%);
}

.language-markdown .token.bold .token.content {
  color: hsl(29, 54%, 61%);
}

.language-markdown .token.italic .token.content {
  color: hsl(286, 60%, 67%);
}

.language-markdown .token.strike .token.content,
.language-markdown .token.strike .token.punctuation,
.language-markdown .token.list.punctuation,
.language-markdown .token.title.important > .token.punctuation {
  color: hsl(355, 65%, 65%);
}

/* General */
.token.bold {
  font-weight: bold;
}

.token.comment,
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.namespace {
  opacity: 0.8;
}

/* Plugin overrides */
/* Selectors should have higher specificity than those in the plugins' default stylesheets */

/* Show Invisibles plugin overrides */
.token.token.tab:not(:empty):before,
.token.token.cr:before,
.token.token.lf:before,
.token.token.space:before {
  color: hsla(220, 14%, 71%, 0.15);
  text-shadow: none;
}

/* Toolbar plugin overrides */
/* Space out all buttons and move them away from the right edge of the code block */
div.code-toolbar > .toolbar.toolbar > .toolbar-item {
  margin-right: 0.4em;
}

/* Styling the buttons */
div.code-toolbar > .toolbar.toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > span {
  background: hsl(220, 13%, 26%);
  color: hsl(220, 9%, 55%);
  padding: 0.1em 0.4em;
  border-radius: 0.3em;
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:focus {
  background: hsl(220, 13%, 28%);
  color: hsl(220, 14%, 71%);
}

/* Line Highlight plugin overrides */
/* The highlighted line itself */
.line-highlight.line-highlight {
  background: hsla(220, 100%, 80%, 0.04);
}

/* Default line numbers in Line Highlight plugin */
.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
  background: hsl(220, 13%, 26%);
  color: hsl(220, 14%, 71%);
  padding: 0.1em 0.6em;
  border-radius: 0.3em;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2); /* same as Toolbar plugin default */
}

/* Hovering over a linkable line number (in the gutter area) */
/* Requires Line Numbers plugin as well */
pre[id].linkable-line-numbers.linkable-line-numbers
  span.line-numbers-rows
  > span:hover:before {
  background-color: hsla(220, 100%, 80%, 0.04);
}

/* Line Numbers and Command Line plugins overrides */
/* Line separating gutter from coding area */
.line-numbers.line-numbers .line-numbers-rows,
.command-line .command-line-prompt {
  border-right-color: hsla(220, 14%, 71%, 0.15);
}

/* Stuff in the gutter */
.line-numbers .line-numbers-rows > span:before,
.command-line .command-line-prompt > span:before {
  color: hsl(220, 14%, 45%);
}

/* Match Braces plugin overrides */
/* Note: Outline colour is inherited from the braces */
.rainbow-braces .token.token.punctuation.brace-level-1,
.rainbow-braces .token.token.punctuation.brace-level-5,
.rainbow-braces .token.token.punctuation.brace-level-9 {
  color: hsl(355, 65%, 65%);
}

.rainbow-braces .token.token.punctuation.brace-level-2,
.rainbow-braces .token.token.punctuation.brace-level-6,
.rainbow-braces .token.token.punctuation.brace-level-10 {
  color: hsl(95, 38%, 62%);
}

.rainbow-braces .token.token.punctuation.brace-level-3,
.rainbow-braces .token.token.punctuation.brace-level-7,
.rainbow-braces .token.token.punctuation.brace-level-11 {
  color: hsl(207, 82%, 66%);
}

.rainbow-braces .token.token.punctuation.brace-level-4,
.rainbow-braces .token.token.punctuation.brace-level-8,
.rainbow-braces .token.token.punctuation.brace-level-12 {
  color: hsl(286, 60%, 67%);
}

/* Diff Highlight plugin overrides */
/* Taken from https://github.com/atom/github/blob/master/styles/variables.less */
pre.diff-highlight > code .token.token.deleted:not(.prefix),
pre > code.diff-highlight .token.token.deleted:not(.prefix) {
  background-color: hsla(353, 100%, 66%, 0.15);
}

pre.diff-highlight > code .token.token.deleted:not(.prefix)::-moz-selection,
pre.diff-highlight > code .token.token.deleted:not(.prefix) *::-moz-selection,
pre > code.diff-highlight .token.token.deleted:not(.prefix)::-moz-selection,
pre > code.diff-highlight .token.token.deleted:not(.prefix) *::-moz-selection {
  background-color: hsla(353, 95%, 66%, 0.25);
}

pre.diff-highlight > code .token.token.deleted:not(.prefix)::selection,
pre.diff-highlight > code .token.token.deleted:not(.prefix) *::selection,
pre > code.diff-highlight .token.token.deleted:not(.prefix)::selection,
pre > code.diff-highlight .token.token.deleted:not(.prefix) *::selection {
  background-color: hsla(353, 95%, 66%, 0.25);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix),
pre > code.diff-highlight .token.token.inserted:not(.prefix) {
  background-color: hsla(137, 100%, 55%, 0.15);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix)::-moz-selection,
pre.diff-highlight > code .token.token.inserted:not(.prefix) *::-moz-selection,
pre > code.diff-highlight .token.token.inserted:not(.prefix)::-moz-selection,
pre > code.diff-highlight .token.token.inserted:not(.prefix) *::-moz-selection {
  background-color: hsla(135, 73%, 55%, 0.25);
}

pre.diff-highlight > code .token.token.inserted:not(.prefix)::selection,
pre.diff-highlight > code .token.token.inserted:not(.prefix) *::selection,
pre > code.diff-highlight .token.token.inserted:not(.prefix)::selection,
pre > code.diff-highlight .token.token.inserted:not(.prefix) *::selection {
  background-color: hsla(135, 73%, 55%, 0.25);
}

/* Previewers plugin overrides */
/* Based on https://github.com/atom-community/atom-ide-datatip/blob/master/styles/atom-ide-datatips.less and https://github.com/atom/atom/blob/master/packages/one-dark-ui */
/* Border around popup */
.prism-previewer.prism-previewer:before,
.prism-previewer-gradient.prism-previewer-gradient div {
  border-color: hsl(224, 13%, 17%);
}

/* Angle and time should remain as circles and are hence not included */
.prism-previewer-color.prism-previewer-color:before,
.prism-previewer-gradient.prism-previewer-gradient div,
.prism-previewer-easing.prism-previewer-easing:before {
  border-radius: 0.3em;
}

/* Triangles pointing to the code */
.prism-previewer.prism-previewer:after {
  border-top-color: hsl(224, 13%, 17%);
}

.prism-previewer-flipped.prism-previewer-flipped.after {
  border-bottom-color: hsl(224, 13%, 17%);
}

/* Background colour within the popup */
.prism-previewer-angle.prism-previewer-angle:before,
.prism-previewer-time.prism-previewer-time:before,
.prism-previewer-easing.prism-previewer-easing {
  background: hsl(219, 13%, 22%);
}

/* For angle, this is the positive area (eg. 90deg will display one quadrant in this colour) */
/* For time, this is the alternate colour */
.prism-previewer-angle.prism-previewer-angle circle,
.prism-previewer-time.prism-previewer-time circle {
  stroke: hsl(220, 14%, 71%);
  stroke-opacity: 1;
}

/* Stroke colours of the handle, direction point, and vector itself */
.prism-previewer-easing.prism-previewer-easing circle,
.prism-previewer-easing.prism-previewer-easing path,
.prism-previewer-easing.prism-previewer-easing line {
  stroke: hsl(220, 14%, 71%);
}

/* Fill colour of the handle */
.prism-previewer-easing.prism-previewer-easing circle {
  fill: transparent;
}

.wp-block-embed__wrapper {
  display: none;
}

.wp-block-table table {
  background-color: white !important;
  @apply mt-5 mb-5 rounded-xl;
}
.wp-block-table table tbody {
  @apply border-t-2 border-2 border-lead100 dark:border-lead500;
}

.wp-block-table table tbody tr td {
  @apply px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4;
}
.wp-block-table table tbody tr {
  @apply border-t-2 border-2 border-lead100 dark:border-lead500;
}

.dark .wp-block-table table {
  background-color: #151515 !important;
}

.article h1 {
  font-size: 36px;
  font-weight: bold;
  font-size: 2.25rem;
}

.article h4 {
  font-size: 22px;
  font-weight: bold;
  font-size: 1.375rem;
}

.article h5 {
  font-size: 20px;
  font-weight: bold;
  font-size: 1.25rem;
}

.article h6 {
  font-size: 19px;
  font-weight: bold;
  font-size: 1.1875rem;
}
.article p {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.article h3 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 1em;
}
.article h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 1em;
}
.article h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  line-height: 1.4;
}

.article .has-text-color strong em {
  color: red;
}
.article .has-text-color p {
  color: red;
}
.article li {
  margin-top: 1em;
}
/* 
.article ul {
  list-style: disc;
}
.article ul, ol {
  margin: 1.5em 0;
  padding: 0 0 0 1.25em;
} */

.article li > ul,
li > ol {
  margin-bottom: 0 !important;
  margin-left: 1.5em !important;
}

.article .wp-block-table table {
  @apply border border-zinc-300 dark:border-zinc-800 p-2 rounded-lg bg-white dark:bg-dark_50;
}
.article .wp-block-table table tbody tr td {
  @apply p-6 border-l border-zinc-300 dark:border-zinc-800;
}
.article .wp-block-table table tbody tr {
  @apply border-b border-zinc-300 dark:border-zinc-800;
}
.article p .has-vivid-red-color {
  @apply text-red-600;
}
.article .wp-block-image {
  margin-top: 24px;
}
.article .wp-block-embed {
  display: none;
}

.article ol,
ul,
menu {
  list-style: disc !important;
  margin-left: 40px !important;
}

.article p a {
  @apply text-brand underline;
}
.article ul li strong a {
  @apply text-brand underline;
}
.article ol li strong a {
  @apply text-brand underline;
}
.article ol li a {
  @apply text-brand underline;
}
.atricle_example_content .wp-block-preformatted {
  @apply border border-zinc-300 dark:border-zinc-800 w-full bg-white dark:bg-dark_40 p-6 rounded-lg mt-4;
  white-space: pre-wrap;
}

.atricle_example_content .wp-block-preformatted p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.code-content {
  @apply h-[400px] overflow-y-auto dark:bg-dark_50 border border-zinc-300 dark:border-zinc-800;
}
.code-block pre {
  @apply p-4;
}

/* Add this to your CSS file */

.code-content {
  display: flex;
  flex-wrap: wrap;
}

.code-block {
  position: relative;
  flex-grow: 1;
  overflow-x: auto;
}

.line-numbers {
  @apply bg-[#f0f0f0] dark:bg-dark_40 border-r border-[#ddd] dark:border-zinc-800;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
  background-color: #f0f0f0; /* Adjust as needed */

  font-size: 16px; /* Adjust as needed */
  color: #666; /* Adjust as needed */
  white-space: pre-wrap;
  pointer-events: none; /* Ensure line numbers do not interfere with code selection */
}

.line-numbers span {
  display: block;
}

.code-block pre.wp-block-code {
  border-radius: 8px;
  margin-left: 30px;
}
.code-block pre.wp-block-code  {
  margin-top: -3px;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin: 40px auto;
  overflow: hidden;
}

.control-container-2 {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
}
.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-image {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.carousel img {
  @apply shadow-[0_3px_10px_rgb(0,0,0,0.2)] dark:shadow-[0_3px_10px_rgb(247,250,252,0.6)];
  width: 80% !important;
  display: block;
}

.image-carousel-arrow {
  @apply dark:bg-[#212121] bg-[#fafafa] hover:dark:bg-[#ff533042] hover:dark:border-brand hover:bg-[#ff533042] hover:border-brand rounded-lg border  border-zinc-300 dark:border-zinc-800 cursor-pointer p-2 transition-all ease-in-out duration-300;
}
.image-carousel-arrow svg {
  @apply stroke-zinc-500 ;
}
.image-carousel-arrow.group:hover svg {
  @apply stroke-brand;
}

.image-carousel-control-button {
  @apply dark:bg-[#212121] bg-[#fafafa] border  border-zinc-300 dark:border-zinc-800 hover:dark:bg-[#ff533042] hover:dark:border-brand hover:bg-[#ff533042] hover:border-brand rounded-lg  cursor-pointer p-2 transition-all ease-in-out duration-300;
}

.image-carousel-control-button svg {
  @apply stroke-zinc-500 stroke-2;
}
.image-carousel-control-button.group:hover svg {
  @apply stroke-brand;
}

.image-carousel-play-button {
  right: 0;
}

.image-carousel-pause-button {
  display: none;
}
.image-number {
  display: none;
}

.image-carousel-down-button {
  left: 10px;
}

.vertical-view {
  display: none;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.hidden-vertical {
  display: none;
}
.shown-vertical {
  display: flex;
}

.vertical-view img {
  margin: 10px 0;
  width: auto;
  height: 100%;
  /* max-width: 800px; */
}

.image-carousel-container {
  @apply flex flex-col items-center gap-x-8;
}
.control-buttons {
  @apply flex flex-row justify-center items-center gap-x-4 mt-4;
}

.tuf-video {
  @apply rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] dark:shadow-[0_3px_10px_rgb(247,250,252,0.6)];
}